import Vue from 'vue';

// stupid didi mand mobile cant add auto
import mandMobile from 'mand-mobile';
import 'mand-mobile/lib-vw/mand-mobile.css';

import {
  Tabbar, NavBar, Row, Col, SearchBar, TimeLine, TimeLineItem, Popup, Steps,
} from '@nutui/nutui';

Tabbar.install(Vue);
NavBar.install(Vue);
Row.install(Vue);
Col.install(Vue);
SearchBar.install(Vue);
TimeLine.install(Vue);
TimeLineItem.install(Vue);
Popup.install(Vue);
Steps.Steps.install(Vue);
Steps.Step.install(Vue);

Vue.use(mandMobile);
