import Vue from 'vue';
import './plugins/ui';
import axios from './plugins/axios';
import router from './router';
import App from './App.vue';

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
