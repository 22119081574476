import axios from 'axios';
import { Toast } from 'mand-mobile';
import router from '../router';

const instance = axios.create({ timeout: 1000 * 12 });

instance.defaults.timeout = 10000;
instance.defaults.headers = { 'Content-Type': 'application/json' };

// axios请求拦截器
instance.interceptors.request.use(
  (config) => {
    const next = { ...config };
    next.url = next.url.replace('/api', 'https://api.panel.bank.doyi.online');
    if (next.method === 'post') next.params = next.data;
    if (localStorage.getItem('loginToken')) {
      next.headers.Authorization = `Bearer ${localStorage.getItem('loginToken')}`;
    }
    return next;
  },
  (error) => Promise.error(error),
);

// axios响应拦截器
instance.interceptors.response.use((response) => response.data, (error) => {
  const { status } = error.response;
  if (status === 401) { // token失效
    Toast.failed('token失效，请重新登录');
    // 清除token
    localStorage.removeItem('loginToken');
    router.push('/');
  }
  return Promise.reject(error);
});

export default instance;
