import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/Main.vue'),
    children: [
      {
        path: '/dashboard',
        name: '控制台',
        component: () => import('../views/Dashboard.vue'),
      },
      {
        path: '/products',
        name: '产品列表',
        component: () => import('../views/Products.vue'),
      },
      {
        path: '/order',
        name: '查看订单',
        component: () => import('../views/Orderlist.vue'),
      },
      {
        path: '/info',
        name: '我的',
        component: () => import('../views/Info.vue'),
      },
      {
        path: '/wallet',
        name: '钱包',
        component: () => import('../views/Wallet.vue'),
      },
      {
        path: '/result/:pid/:oid',
        name: '结果',
        component: () => import('../views/Result.vue'),
      },
      {
        path: '/detail/:pid/:oid',
        name: '订单明细',
        component: () => import('../views/Detail.vue'),
      },
      {
        path: '/product/:id',
        name: '购买',
        component: () => import('../views/Product.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
